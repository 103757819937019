import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import classes from './AppHeader.module.scss';
import UserPool from '../../utils/UserPool';
import { useSelector } from 'react-redux';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { AccountType } from '../../constants/common';

import {
	Expense,
	Home,
	Profile,
	MenuBar,
	ChevronClose,
	Doc,
	Faq,
	Message,
	Attachment,
	Logout,
	Pencil
} from '../icons';
import { ContactUs } from '../ContactUs';
import { APP_VERSION } from '../../constants/version';
import { Button, ButtonSizes, ButtonTypes } from '../common/Button';
import { isAccountClosed } from '../../utils/accountUtils';

type TSideBarItems = {
	name: string;
	icon: React.ReactElement;
	action: () => void;
	isVisible: boolean;
};

export const AppHeader = () => {
	const [isSideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
	const navigate = useNavigate();
	const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
	const [isHsa, setIsHsa] = useState<boolean>(false);

	const summaryData = useSelector((state: any) => state.summaryData);

	const wrapperRef: any = useRef(null);
	const sidebarCloseRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setSideMenuOpen(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [wrapperRef]);

	useEffect(() => {
		if (!summaryData.loading) {
			const hsaPresent = summaryData.data?.hsa_summary.find(
				(account: { type: string; account_status: string }) => account.type == AccountType.HSA
			);
			if (hsaPresent) {
				setIsHsa(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [summaryData]);

	// Clear the local storage and redirect to login page
	const signOutUser = () => {
		try {
			const cognitoUser: any = UserPool.getCurrentUser();
			cognitoUser.signOut();
		} catch (error) {}

		localStorageUtil.clearTokens();
		localStorage.removeItem('card-activation-dismissed');
		localStorage.removeItem('card-travel-dismissed');
		localStorage.removeItem('grace-card-dismissed');
		localStorage.removeItem('runout-card-dismissed');
		Object.keys(localStorage)
			.filter(key => key.startsWith('suspended-card-dismissed'))
			.forEach(key => localStorage.removeItem(key));
		navigate('/login');
	};

	// SideBar Items | Item object contains : name, icon(Icon in Left), action(onClick), isVisible(Should it be Visible in Sidebar?)
	const sidebarItems: TSideBarItems[] = [
		{
			name: 'Tax Docs & Statements',
			icon: <Doc color="#FFFFFF" />,
			action: () => {
				navigate('/apps/statements/hsa');
				setSideMenuOpen(false);
			},
			isVisible: isHsa
		},
		{
			name: 'FAQ',
			icon: <Faq color="#FFFFFF" />,
			action: () => {
				window.open('https://www.incomm.com/benefits/spending-account-faq/');
				setSideMenuOpen(false);
			},
			isVisible: true
		},
		{
			name: 'Contact Us',
			icon: <Message color="#FFFFFF" />,
			action: () => {
				setIsContactUsOpen(true);
				setSideMenuOpen(false);
			},
			isVisible: true
		},
		{
			name: 'Legal Documents',
			icon: <Attachment color="#FFFFFF" />,
			action: () => {
				window.open('https://incommbenefits.com/legal');
				setSideMenuOpen(false);
			},
			isVisible: true
		},
		{
			name: 'Important Forms',
			icon: <Pencil color="#FFFFFF" />,
			action: () => {
				window.open('https://www.incomm.com/benefits/important-forms/');
				setSideMenuOpen(false);
			},
			isVisible: true
		},
		{
			name: 'Sign Out',
			icon: <Logout color="#FFFFFF" />,
			action: signOutUser,
			isVisible: true
		}
	];

	return (
		<React.Fragment>
			<ContactUs
				openModal={isContactUsOpen}
				closeModal={() => {
					setIsContactUsOpen(false);
				}}
				eventPage="Header"
			/>
			{/* Sidebar */}
			<div
				className={`${classes['side-bar']} ${isSideMenuOpen ? classes['side-bar-open'] : ''}`}
				ref={wrapperRef}
			>
				<div className="d-flex justify-content-start mb-32">
					<Button
						type={ButtonTypes.TRANSPARENT}
						size={ButtonSizes.DEFAULT}
						otherClasses={classes['close-icon']}
						onClick={() => {
							setSideMenuOpen(!isSideMenuOpen);
						}}
						aria-label="Close sidebar"
						aria-expanded={isSideMenuOpen}
						buttonRef={sidebarCloseRef}
					>
						<ChevronClose title="Close the sidebar" titleId="closethesidebar" color="#FFFFFF" />
					</Button>
				</div>

				{sidebarItems.map(item => {
					if (!item.isVisible) {
						return;
					}
					return (
						<Button
							type={ButtonTypes.TRANSPARENT}
							size={ButtonSizes.DEFAULT}
							otherClasses={classes['side-bar-option']}
							onClick={item.action}
						>
							<div className="d-flex align-items-center">
								<span className="d-flex align-items-center px-8">{item.icon}</span>

								<p className={classes['side-bar-option-text']}>{item.name}</p>
							</div>
							<div className="d-flex align-items-center">
								<ChevronClose color="#FFFFFF" />
							</div>
						</Button>
					);
				})}

				<div className="d-flex justify-content-center mt-32">Version {APP_VERSION}</div>
			</div>
			{/* Navbar - Home | Expenses | Profile */}
			<div className={classes.container}>
				<div className={classes['menu-bar']}>
					{!isSideMenuOpen && (
						<Button
							type={ButtonTypes.TRANSPARENT}
							size={ButtonSizes.DEFAULT}
							otherClasses={classes['side-bar-icon']}
							onClick={() => {
								setSideMenuOpen(true);
								sidebarCloseRef.current?.focus();
							}}
							aria-label="Open sidebar"
							aria-expanded={!isSideMenuOpen}
						>
							<MenuBar width={24} color="#fafcfe" title="Open Sidebar" titleId="opensidebar" />
						</Button>
					)}
				</div>
				<div className={classes.menu}>
					<NavLink
						to={'/apps/dashboard'}
						className={({ isActive }) => 'custom-nav-link ' + (isActive ? 'active' : '')}
					>
						<Home width={24} aria-hidden />
						<span className="sm-hide d-flex align-items-center">Home</span>
					</NavLink>
					<NavLink
						to={'/apps/expenses/summary'}
						className={({ isActive }) =>
							'custom-nav-link ' +
							(isActive || location.pathname.includes('apps/expense') ? 'active' : '')
						}
					>
						<Expense width={24} aria-hidden />
						{summaryData.data.expense_count && !isAccountClosed(summaryData.data) ? (
							<span className={classes['sup-badge']}>{summaryData.data.expense_count}</span>
						) : (
							''
						)}

						<span className="sm-hide d-flex align-items-center">Expenses</span>
					</NavLink>
					<NavLink
						to={'/apps/profile'}
						className={({ isActive }) => 'custom-nav-link ' + (isActive ? 'active' : '')}
					>
						<Profile width={24} aria-hidden />
						<span className="sm-hide d-flex align-items-center">Profile</span>
					</NavLink>
				</div>
			</div>
		</React.Fragment>
	);
};
