/* eslint-disable max-lines */
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import classes from './DocumentUpload.module.scss';
import { StyledLink } from '../../common/Link';
import { Button, ButtonSizes, ButtonTypes } from '../../common/Button';
import pdfImage from '../../../assets/img/pdf.png';
import uploadIcon from '../../../assets/img/uploadIcon.svg';
import { Banner, BannerTypes } from '../../common/Banner';
import { Warning, Car, Hsa } from '../../icons';
import { Loader } from '../../../components/common/Loader';
import { fileUtils } from '../../../utils/fileUtil';
import {
	DocumentUploadModule,
	EvaluationType,
	ExpenseGroupType,
	ReimburseExpenseType,
	TransactionAndExpenseDes,
	UploadScanStatus
} from '../../../constants/common';
import { IExpense } from '../../../interface/common.interface';
import { expenseApi } from '../../../services/expenseService';
import { Oval } from 'react-loader-spinner';
import { Radio } from '../../common/Radio/Radio';
import { RadioSizes } from '../../common/Radio';
import { transactionsApi } from '../../../services/transactionsService';
import { getDecimalVal } from '../../../utils/numberUtil';
import { dateUtil } from '../../../utils/dateUtil';
import { useNavigate } from 'react-router-dom';
import IconNavLink from '../../common/IconNavLink';
import { ICON_NAV_LINK_TYPES } from '../../common/IconNavLink/IconNavLink.constants';

type StepProps = {
	nextStep: (increaseBy?: number) => void;
	previousStep: () => void;
	expenseDetails: {
		expenseFormData: Record<string, string | null>;
		expenseDetailsSuccess: Record<string, string | boolean>;
		attachments: Array<Record<string, string | number>>;
	};
	updateAttachments: (attachments: Array<Record<string, any>>) => void;
	updateEvaluateData: (evaluateData: Record<string, any>) => void;
	updateExpenseDetails: (details: Record<string, string | null>) => void;
	updateExpenseDetailsSuccess: (details: Record<string, string | boolean>) => void;
};

const PAGE_SIZE = 10;

export const DocumentUpload = ({
	nextStep,
	previousStep,
	expenseDetails,
	updateAttachments,
	updateEvaluateData,
	updateExpenseDetails,
	updateExpenseDetailsSuccess
}: StepProps) => {
	const fileInputRef = useRef<any>(null);
	const scanIntervalRef = useRef<any>(null);
	const [documentList, setDocumentList] = useState<Array<string>>([]);
	const [customError, setCustomError] = useState<{ message: string }>({ message: '' });
	const [numberOfDocumentProcessing, setNumberOfDocumentProcessing] = useState<number>(0);
	const [pagination, setPagination] = useState<{ offset: number; limit: number }>({
		offset: 0,
		limit: PAGE_SIZE
	});
	const [travelExpenseList, setTravelExpenseList] = useState<Array<any>>([]);
	const [showTravelExpense, setShowTravelExpense] = useState<boolean>(false);
	const [selectedExpenseId, setSelectedExpenseId] = useState<string>('');
	const [showLoadMore, setShowLoadMore] = useState<boolean>(true);

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [uploadImageList, setUploadImageList] = useState<
		Array<{
			id: number;
			name: string;
			image: File;
			// compressedFile: File | string;
			base64?: string;
			scanPercent: number;
			// compressedFileName: string;
		}>
	>([]);
	const [isScanning, setIsScanning] = useState<boolean>(false);
	const [uploadStatusMessage, setUploadStatusMessage] = useState('');

	const getEvaluated = () => {
		setIsLoading(true);
		const requestQuery = {
			expense_draft_id: expenseDetails.expenseDetailsSuccess.expense_draft_id,
			evaluation_type: EvaluationType.DEFAULT
		};
		setIsLoading(true);
		expenseApi
			.getEvaluate(requestQuery)
			.then(response => {
				setIsLoading(false);
				updateEvaluateData(response.data);
				nextStep();
			})
			.catch();
	};

	const getExpenseGroup = () => {
		setIsLoading(true);
		transactionsApi
			.getExpenseByGroup(ExpenseGroupType.TRAVEL_POTENTIAL.toUpperCase(), pagination)
			.then(response => {
				setIsLoading(false);
				setTravelExpenseList([...travelExpenseList, ...response.data.data]);
				if (response.data.data.length % PAGE_SIZE !== 0 || response.data.data.length == 0) {
					setShowLoadMore(false);
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const updateTravelExpense = () => {
		setIsLoading(true);
		expenseApi
			.updateExpenseDraft(expenseDetails.expenseDetailsSuccess.expense_draft_id as string, [
				{
					op: 'replace',
					path: '/parent_expense_id',
					value: selectedExpenseId
				}
			])
			.then(response => {
				updateExpenseDetailsSuccess(response.data.data);
				setIsLoading(false);
				setShowTravelExpense(false);
				updateExpenseDetails({
					...expenseDetails.expenseFormData,
					parent_expense_id: selectedExpenseId
				} as unknown as Record<string, string | null>);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getExpenseGroup();
		if (!!Number(expenseDetails.expenseFormData.expense_related)) {
			setShowTravelExpense(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
			if (scanIntervalRef.current) {
				clearInterval(scanIntervalRef.current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (expenseDetails?.expenseFormData?.parent_expense_id) {
			setSelectedExpenseId(expenseDetails?.expenseFormData?.parent_expense_id);
		}
	}, [expenseDetails.expenseFormData]);

	const getFileNames = (fileName: string) => {
		const fileNameArray = fileName.split('.');
		const ext = fileNameArray.pop();
		let actualFileName = fileNameArray.join('.');

		const documentWithSameName = documentList.filter(documentName =>
			documentName.toLowerCase().includes(actualFileName.toLowerCase())
		);

		if (documentWithSameName?.length) {
			actualFileName = `${actualFileName}(${documentWithSameName.length})`;
		}

		setDocumentList([...documentList, `${actualFileName}.${ext}`]);

		return `${actualFileName}.${ext}`;
	};

	const validTypesType = async (files: any) => {
		for (let i = 0; i < files.length; i = i + 1) {
			try {
				await fileUtils.validateFileType(files[i]);
			} catch (err: any) {
				return false;
			}
		}
		return true;
	};

	const validNumberOfFile = (files: any, validNumber = 10) => {
		return [...expenseDetails.attachments, ...uploadImageList, ...files].length > validNumber
			? false
			: true;
	};

	const uploadToS3 = (file: any, signedUrl: string) => {
		const options = {
			headers: {
				'Content-Type': file.type
			}
		};
		return axios.put(signedUrl, file, options);
	};

	const uploadDocument = async () => {
		window.scrollTo(0, 0);
		const expenseFiles: Array<string> = [];
		uploadImageList.map(imageData => {
			expenseFiles.push(imageData.name);
		});
		setIsScanning(true);
		const fileUploadDetail = {
			service: 'consumer',
			module: DocumentUploadModule.EXPENSE_DRAFT,
			payload: {
				expense_draft_id: expenseDetails.expenseDetailsSuccess.expense_draft_id
			},
			file_names: expenseFiles
		};
		expenseApi
			.fileUpload(fileUploadDetail)
			.then(response => {
				let apiResponseSignedUrls: Array<{ url: string; file_name: string; file_id: string }> =
					response.data.presigned_url;
				const uploadedFiles: any = [];
				let attachmentsFromExpense: any = expenseDetails.attachments?.length
					? [...expenseDetails.attachments, ...uploadImageList]
					: uploadImageList;
				updateAttachments(attachmentsFromExpense);
				setUploadImageList([]);
				apiResponseSignedUrls.forEach(apiResponse => {
					const file = uploadImageList.find(image => image.name == apiResponse.file_name);

					console.log(file, apiResponse);

					if (file) {
						if (file.name == apiResponse.file_name) {
							uploadedFiles.push(uploadToS3(file.image, apiResponse.url));
						}
					}
				});
				Promise.all(uploadedFiles).then(() => {
					setUploadImageList([]);
					scanIntervalRef.current = setInterval(() => {
						const scanQueue: any = [];

						if (!apiResponseSignedUrls.length) {
							clearInterval(scanIntervalRef.current);
							setIsScanning(false);
							return;
						}
						apiResponseSignedUrls.forEach(signedUrl => {
							scanQueue.push(expenseApi.getScannedFile(signedUrl.file_id));
						});

						Promise.all(scanQueue)
							.then(scannedResult => {
								const scannedFiles = scannedResult.map(result => result.data.data);
								attachmentsFromExpense = attachmentsFromExpense.map((attachment: any) => {
									const scanFileResult = scannedFiles.find(
										scannedFile => scannedFile.file_name == attachment.name
									);

									if (scanFileResult) {
										attachment.scanPercent = scanFileResult.progress_percentage || 0;
										attachment.status = scanFileResult.status;

										if (
											[
												UploadScanStatus.FILE_ACCEPTED,
												UploadScanStatus.FILE_REJECTED,
												UploadScanStatus.ERRORED_OUT
											].includes(scanFileResult.status)
										) {
											apiResponseSignedUrls = apiResponseSignedUrls.filter(
												signedUrl => !(signedUrl.file_name == attachment.name)
											);
										}
									}
									return attachment;
								});
								updateAttachments(attachmentsFromExpense);
							})
							.then(console.log);
					}, 1000);
				});
			})
			.catch(() => {
				//setIsLoading(false);
			});
	};

	useEffect(() => {
		if (uploadImageList.length && !numberOfDocumentProcessing) {
			uploadDocument();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadImageList, numberOfDocumentProcessing]);

	const handelInputFiles = async (files: any) => {
		if (!fileUtils.validFilesSize(files)) {
			setCustomError({ message: 'Maximum 10 MB document size allowed.' });
			return;
		}
		if (!(await validTypesType(files))) {
			setCustomError({
				message: 'Invalid document format, Allowed formats are jpeg, jpg, png, pdf.'
			});
			return;
		}
		if (!validNumberOfFile(files)) {
			setCustomError({ message: 'Maximum 10 documents allowed.' });
			return;
		}

		if (!fileUtils.validateFileName(files)) {
			setCustomError({ message: 'Invalid file name' });
			return;
		}
		setCustomError({ message: '' });
		for (let i = 0; i < files.length; i = i + 1) {
			try {
				if (files[i].type.startsWith('image/')) {
					setNumberOfDocumentProcessing(previousValue => previousValue + 1);
					const reader = new FileReader();
					reader.addEventListener(
						'load',
						async () => {
							const fileName = getFileNames(fileUtils.sanitizeFileName(files[i].name));
							setUploadImageList(prevState => [
								...prevState,
								{
									id: uploadImageList.length,
									name: fileName,
									image: files[i],
									file_type: files[i].type.split('/').pop(),
									base64: reader.result as string,
									scanPercent: 0,
									status: UploadScanStatus.IN_PROGRESS
								}
							]);
							setNumberOfDocumentProcessing(previousValue => previousValue - 1);
						},
						false
					);
					reader.readAsDataURL(files[i]);
				} else if (files[i].type == 'application/pdf') {
					const fileName = getFileNames(fileUtils.sanitizeFileName(files[i].name));
					setUploadImageList(prevState => [
						...prevState,
						{
							id: uploadImageList.length,
							name: fileName,
							image: files[i],
							file_type: files[i].type.split('/').pop(),
							base64: '',
							scanPercent: 0,
							status: UploadScanStatus.IN_PROGRESS
						}
					]);
				}

				setUploadStatusMessage('Document Uploaded Successfully');
			} catch (error) {
				console.log(error);
			}
		}
		//uploadDocument();
	};

	const removeUploaded = (file: Record<string, string | number>) => {
		if (
			[UploadScanStatus.ERRORED_OUT, UploadScanStatus.FILE_REJECTED].includes(
				file.status as UploadScanStatus
			)
		) {
			const remainingFiles = expenseDetails.attachments.filter(
				attachment => attachment.name != file.name
			);
			updateAttachments(remainingFiles);
		} else {
			setIsLoading(true);
			expenseApi
				.deleteExpenseDocuments(expenseDetails.expenseDetailsSuccess.expense_draft_id as string, {
					delete_file_list: [file.name]
				})
				.then(() => {
					const remainingFiles = expenseDetails.attachments.filter(
						attachment => attachment.name != file.name
					);
					setUploadImageList(uploadImageList.filter(image => image.name != file.name));

					updateAttachments(remainingFiles);
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	};

	const getButtonText = () => {
		if (
			!expenseDetails?.expenseDetailsSuccess?.is_attachments_mandatory &&
			!uploadImageList.length &&
			!expenseDetails?.attachments.length
		) {
			return 'Skip';
		} else {
			return 'Next';
		}
	};

	const getDesClass = (expense: IExpense) => {
		switch (expense.description?.toLowerCase()) {
			case TransactionAndExpenseDes.RECEIPT_RECOMMENDED:
			case TransactionAndExpenseDes.REVIEW_FOR_ELIGIBILITY:
			case TransactionAndExpenseDes.PARTIALLY_REIMBURSED:
			case TransactionAndExpenseDes.NEEDS_ADDITIONAL_INFO:
				return classes['yellow-pill'];
			case TransactionAndExpenseDes.ELIGIBLE_FOR_REIMBURSEMENT:
				return classes['green-pill'];
			default:
				return '';
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="d-flex flex-column  mt-64">
			{showTravelExpense && (
				<>
					{travelExpenseList.length ? (
						<>
							<div className={classes['select-expense-header']}>
								<p className="text-base-700 text-primary">
									Select the corresponding health expense.
								</p>
							</div>
							<div className="my-16">
								{travelExpenseList.map((expense, index) => (
									<IconNavLink
										key={`${expense.id}-${index}`}
										type={ICON_NAV_LINK_TYPES.STATIC}
										leftIcon={<Hsa width={32} height={32} />}
										title={expense.activity}
										mainSubtitle={
											<span className={`text-sm-325 ${getDesClass(expense)}`}>
												{expense.description}
											</span>
										}
										additionalInfo={`$${getDecimalVal(expense.amount)}`}
										additionalSubInfo={dateUtil.getFormattedDate(expense?.date * 1000, 'MMMM DD')}
										hasRightArrow={false}
										rightComponent={
											<Radio
												checked={selectedExpenseId == expense.id}
												value={expense.id}
												name="expense"
												size={RadioSizes.LARGE}
												onChange={() => {
													setSelectedExpenseId(expense.id);
												}}
											/>
										}
										isLast={index === travelExpenseList.length - 1}
										isFirst={index === 0}
									/>
								))}
							</div>
							{showLoadMore && (
								<div className="text-center py-12 my-16">
									<StyledLink
										onClick={() => {
											setPagination({
												offset: pagination.offset + PAGE_SIZE,
												limit: pagination.limit
											});
										}}
									>
										Load More Expenses
									</StyledLink>
								</div>
							)}
						</>
					) : (
						<>
							<div className={classes['activity-container']}>
								<div>
									<p className={classes['no-activity-text']}>
										You currently do not have any transactions for display.
									</p>
								</div>
							</div>
						</>
					)}

					<div className={`row ${classes['reverse-order']}`}>
						<div className=" d-flex flex-column cl-md-6 pt-24">
							<Button onClick={previousStep} type={ButtonTypes.SECONDARY}>
								Back
							</Button>
						</div>
						<div className=" d-flex flex-column cl-md-6 pt-24">
							<Button isDisabled={!selectedExpenseId} onClick={updateTravelExpense}>
								Next
							</Button>
						</div>
					</div>
				</>
			)}
			{!showTravelExpense && (
				<>
					{expenseDetails.expenseFormData.expense_type == ReimburseExpenseType.TRAVEL &&
						expenseDetails?.expenseDetailsSuccess?.is_attachments_mandatory &&
						expenseDetails.expenseFormData?.expense_related != '1' &&
						selectedExpenseId.length <= 0 && (
							<div>
								<p className="text-base-325 text-center">
									If you used another form of payment, you can create a single expense and include
									your travel expenses for a single reimbursement.
								</p>
								<p className="py-12 text-center">
									<StyledLink
										onClick={() => {
											navigate(0);
										}}
									>
										Submit an expense for reimbursement
									</StyledLink>
								</p>
								<hr aria-hidden="true" className="my-32" />
							</div>
						)}
					<h2 className={'text-h5-700 text-primary'}>Upload your expense documents</h2>
					<p className="text-base-325 py-12 text-primary">
						Attach proof documents to your expense as part of your submission.
					</p>
					{expenseDetails?.expenseDetailsSuccess?.is_attachments_mandatory &&
						expenseDetails.expenseFormData.expense_type != ReimburseExpenseType.TRAVEL && (
							<Banner type={BannerTypes.WARNING} icon={Warning} fill={true}>
								<div className={`d-flex align-self-center text-sm-325 text-primary`}>
									Documentation is required for {expenseDetails.expenseFormData.expense_type}{' '}
								</div>
							</Banner>
						)}
					{expenseDetails?.expenseDetailsSuccess?.is_attachments_mandatory &&
						expenseDetails.expenseFormData.expense_type == ReimburseExpenseType.TRAVEL && (
							<div className="d-flex flex-column gap-12">
								<Banner type={BannerTypes.WARNING} icon={Car} fill={true}>
									<div className={`d-flex align-self-center text-sm-325 text-primary`}>
										Documentation is required for your travel expenses.
									</div>
								</Banner>
								{selectedExpenseId.length <= 0 && (
									<Banner type={BannerTypes.WARNING} icon={Warning} fill={true}>
										<div className={`d-flex align-self-center text-sm-325 text-primary`}>
											Also, you will need to provide documentation that shows you had a health
											related expense on the day you traveled.
										</div>
									</Banner>
								)}
							</div>
						)}

					<div className="py-12">
						<p className="text-base-325 text-primary">
							Please ensure your document(s) has the following:
						</p>
						<ul>
							<li className="text-base-325 text-primary">Date of service</li>
							<li className="text-base-325 text-primary">Provider or merchant name</li>
							<li className="text-base-325 text-primary">
								Details of the service or product received and the amount charged.
							</li>
						</ul>
						<p className="text-base-325 text-primary">
							Itemized receipts, bills or an explanation of benefits (EOB) document are all
							sufficient proof documents.
						</p>

						<h2 className={'text-h5-325 text-primary mt-32 mb-12'}>Documents</h2>

						<hr aria-hidden="true" className="m-0" />

						{numberOfDocumentProcessing ? <Loader /> : ''}

						{expenseDetails.attachments.map((attachment, index) => {
							return (
								<React.Fragment key={`uploaded-file-list-${index}`}>
									<div
										key={`uploaded-file-list-${index}`}
										className={`d-flex justify-content-between align-items-center py-24 px-12 ${
											[UploadScanStatus.FILE_REJECTED, UploadScanStatus.ERRORED_OUT].includes(
												attachment.status as UploadScanStatus
											)
												? classes['error-document']
												: null
										}`}
										onDrop={(e: any) => {
											handelInputFiles(e.dataTransfer.files);
											e.preventDefault();
										}}
										onDragOver={e => {
											e.preventDefault();
										}}
									>
										<div className={`d-flex align-items-center`}>
											<img
												alt=""
												onClick={() => {
													if (attachment.base64) {
														const image = new Image();
														image.src = attachment.base64 as string;
														const w = window.open('');
														w?.document.write(image.outerHTML);
													} else {
														const blob = new Blob([attachment.image as BlobPart], {
															type: (attachment.image as any).type
														});
														const fileURL = window.URL.createObjectURL(blob);
														window.open(fileURL, '_blank');
														window.URL.revokeObjectURL(fileURL);
													}
												}}
												className={classes['image-position']}
												src={(attachment.base64 as string) || pdfImage}
												width={'50'}
												height={'50'}
											/>
											<p className="text-base-325 px-12">{attachment.name || ''}</p>
										</div>
										<div className="px-16">
											{attachment.scanPercent === 100 && (
												<span className="visually-hidden" role="status" aria-live="polite">
													{uploadStatusMessage}
												</span>
											)}
											{[
												UploadScanStatus.FILE_ACCEPTED,
												UploadScanStatus.ERRORED_OUT,
												UploadScanStatus.FILE_REJECTED
											].includes(attachment.status as UploadScanStatus) ? (
												<Button
													type={ButtonTypes.TRANSPARENT}
													size={ButtonSizes.DEFAULT}
													otherClasses="link-btn"
													onClick={() => {
														setUploadStatusMessage('');
														removeUploaded(attachment);
														setUploadStatusMessage('Document Removed Successfully');
													}}
												>
													Remove
												</Button>
											) : (
												<div
													className="d-flex justify-content-center align-items-center gap-20"
													role="status"
												>
													<Oval
														height={45}
														width={45}
														color="#296db2"
														wrapperStyle={{
															maxHeight: '45px',
															maxWidth: '45px',
															alignItems: 'center',
															justifyContent: 'center'
														}}
														wrapperClass=""
														visible={true}
														ariaLabel="oval-loading"
														secondaryColor="#fafcfe"
														strokeWidth={4}
														strokeWidthSecondary={4}
													/>
													<span className="visually-hidden">Uploading Document </span>
													{attachment.scanPercent}%
												</div>
											)}
										</div>
									</div>
									<hr aria-hidden="true" className="m-0" />
								</React.Fragment>
							);
						})}

						{uploadImageList && uploadImageList.length
							? uploadImageList.map((section, index) => {
									return (
										<React.Fragment key={`upload-file-list-${index}`}>
											<div
												key={`uploaded-file-list-${index}`}
												className={`d-flex justify-content-between align-items-center py-24 px-12`}
												onDrop={(e: any) => {
													handelInputFiles(e.dataTransfer.files);
													e.preventDefault();
												}}
												onDragOver={e => {
													e.preventDefault();
												}}
											>
												<div className="d-flex align-items-center">
													<img
														alt=""
														onClick={() => {
															if (section.base64) {
																const image = new Image();
																image.src = section.base64 as string;
																const w = window.open('');
																w?.document.write(image.outerHTML);
															} else {
																const blob = new Blob([section.image], {
																	type: section.image.type
																});
																const fileURL = window.URL.createObjectURL(blob);
																window.open(fileURL, '_blank');
																window.URL.revokeObjectURL(fileURL);
															}
														}}
														className={classes['image-position']}
														src={section.base64 || pdfImage}
														width={'50'}
														height={'50'}
													/>
													<p className="text-base-325 px-12">{section.name || ''}</p>
												</div>
												<div>
													<div className="px-8">
														<div className="d-flex justify-content-center align-items-center gap-20">
															<Oval
																height={45}
																width={45}
																color="#296db2"
																wrapperStyle={{
																	maxHeight: '45px',
																	maxWidth: '45px',
																	alignItems: 'center',
																	justifyContent: 'center'
																}}
																wrapperClass=""
																visible={true}
																ariaLabel="oval-loading"
																secondaryColor="#fafcfe"
																strokeWidth={4}
																strokeWidthSecondary={4}
															/>
															{section.scanPercent}%
														</div>
													</div>
												</div>
											</div>
											<hr aria-hidden="true" className="my-0" />
										</React.Fragment>
									);
							  })
							: ''}

						{uploadImageList.length ||
						expenseDetails.attachments.length ||
						numberOfDocumentProcessing ? (
							''
						) : (
							<div
								className="d-flex justify-content-center py-32"
								onClick={() => {
									fileInputRef?.current?.click();
								}}
								onDrop={(e: any) => {
									handelInputFiles(e.dataTransfer.files);
									e.preventDefault();
								}}
								onDragOver={e => {
									e.preventDefault();
								}}
							>
								<img src={uploadIcon} width={75} height={75} alt="" />
							</div>
						)}

						{/* {uploadImageList?.length == 0 ? <hr aria-hidden="true" className="my-24" /> : ''} */}
						{numberOfDocumentProcessing ? (
							''
						) : (
							<div
								className="d-flex justify-content-center py-24"
								onDrop={(e: any) => {
									setUploadStatusMessage('');
									handelInputFiles(e.dataTransfer.files);
									e.preventDefault();
								}}
								onDragOver={e => {
									e.preventDefault();
								}}
							>
								<input
									ref={fileInputRef}
									className={classes['input-file']}
									accept="image/*,application/pdf"
									multiple
									onClick={(event: any) => {
										event.target.value = '';
									}}
									onChange={e => {
										handelInputFiles(e.target.files);
									}}
									type="file"
								></input>
								<StyledLink
									onClick={() => {
										setUploadStatusMessage('');
										fileInputRef?.current?.click();
									}}
									isDisabled={isScanning}
								>
									<span className="text-base-350">Add a Document</span>
								</StyledLink>
							</div>
						)}
						{customError && customError.message && (
							<>
								<br />
								<span className={classes.banner}>
									<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
										<div className="d-flex align-items-center">{customError.message}</div>
									</Banner>
								</span>
							</>
						)}
						{expenseDetails.attachments.some(attachment =>
							[UploadScanStatus.FILE_REJECTED].includes(attachment.status as UploadScanStatus)
						) && (
							<>
								<br />
								<span className={classes.banner}>
									<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
										<div className="d-flex align-items-center">
											<b>Upload Rejected!&nbsp; </b> Please try uploading another file.
										</div>
									</Banner>
								</span>
							</>
						)}

						{expenseDetails.attachments.some(attachment =>
							[UploadScanStatus.ERRORED_OUT].includes(attachment.status as UploadScanStatus)
						) && (
							<>
								<br />
								<span className={classes.banner}>
									<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
										<div className="d-flex align-items-center">
											<b>Upload Error!&nbsp;</b> Please try uploading again.
										</div>
									</Banner>
								</span>
							</>
						)}

						<hr aria-hidden="true" className="m-0" />
						<div className={`row ${classes['reverse-order']}`}>
							<div className=" d-flex flex-column cl-md-6 pt-24">
								<Button
									onClick={() => {
										if (expenseDetails.expenseFormData?.expense_related == '1') {
											setShowTravelExpense(true);
										} else {
											previousStep();
										}
									}}
									type={ButtonTypes.SECONDARY}
									isDisabled={isScanning}
								>
									Back
								</Button>
							</div>
							<div className=" d-flex flex-column cl-md-6 pt-24">
								<Button
									isDisabled={
										(!!expenseDetails.expenseDetailsSuccess.is_attachments_mandatory &&
											!uploadImageList.length &&
											!expenseDetails.attachments.length) ||
										!!numberOfDocumentProcessing ||
										isScanning ||
										(!!expenseDetails.expenseDetailsSuccess.is_attachments_mandatory &&
											expenseDetails.attachments.some(attachment =>
												[UploadScanStatus.FILE_REJECTED, UploadScanStatus.ERRORED_OUT].includes(
													attachment.status as UploadScanStatus
												)
											))
									}
									onClick={getEvaluated}
								>
									{getButtonText()}
								</Button>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
