// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgPencil = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 18 18"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill="#fff"
			d="M1.21 17.52a.73.73 0 0 1-.697-.485.75.75 0 0 1-.043-.325l.31-3.84A.73.73 0 0 1 1 12.4L12.06 1.34a3.2 3.2 0 0 1 2.28-.86 3.3 3.3 0 0 1 2.25.91 3.31 3.31 0 0 1 .11 4.5L5.63 17a.77.77 0 0 1-.46.22l-3.89.35zm1-4.26L2 16l2.74-.25 10.9-10.92A1.72 1.72 0 0 0 14.31 2a1.6 1.6 0 0 0-1.19.42z"
		/>
	</svg>
);
export default SvgPencil;
